import { IAutocomplete } from '@/domain/models';

const banks: Array<string> = [
    'Itaú',
    'Bradesco',
    'Caixa Econômica',
    'Banco do Brasil',
    'Santander',
    'Banrisul',
    'Sicredi',
    'Sicoob',
    'Inter',
    'BRB',
    'Via Credi',
    'Neon',
    'Votorantim',
    'Nubank',
    'Pagseguro',
    'Banco Original',
    'Safra',
    'Modal',
    'Banestes',
    'Unicred',
    'Money Plus',
    'Mercantil do Brasil',
    'JP Morgan',
    'Gerencianet Pagamentos do Brasil',
    'Banco C6',
    'BS2',
    'Banco Topazio',
    'Uniprime',
    'Stone',
    'Banco Daycoval',
    'Rendimento',
    'Banco do Nordeste',
    'Citibank',
    'PJBank',
    'Cooperativa Central de Credito Noroeste Brasileiro',
    'Uniprime Norte do Paraná',
    'Global SCM',
    'Next',
    'Cora',
    'Mercado Pago',
    'Banco da Amazonia',
    'BNP Paribas Brasil',
    'Juno',
    'Cresol',
    'BRL Trust DTVM',
    'Banco Banese',
    'Banco BTG Pactual',
    'Banco Omni',
    'Picpay',
    'PagBank',
];

const banksList: IAutocomplete[] = banks.sort().map((bank, index) => {
    return {
        id: index.toString(),
        label: bank,
    };
});

export default banksList;
